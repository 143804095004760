.series-block {
    // box-shadow: 0 0 10px 10px #707070;
    padding: 20px;
    border-radius: 20px;
    width: 100% !important;
    .series-block__img-container {
        img {
            max-width: 176px;
            max-height: 236px;
        }
    }
    .series-block__info__container {
        height: 100%;
        .series-block__info {
            .series-block__info__title {
                font-size: 20px;
                font-weight: 600;
                color: #6b6b6b;
            }
            .series-block__info__desc {
                color: #6b6b6b;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .series-block__btn {
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 154.84px;
                height: 34.08px;
                border-radius: 25px;
                background-color: $third-text-color;
                color: #fff;
                font-size: 13px;
                border: 1px solid $third-text-color;
                transition: 0.3s;
                &:hover {
                    background-color: #fff;
                    transition: 0.3s;
                    color: $third-text-color;
                }
            }
        }
    }
}

// .contact-us-arabic {
//     .series-block__btn {
//         text-align: left !important;
//     }
//     .accordion-button::after {
//         margin-left: 0;
//         margin-right: auto;
//     }
// }
