.home {
    .agent-of {
        background-color: #fbfbfb;
    }
}

.agent-of {
    background-color: #f0f4f7;
    padding: 40px 0;
    margin-top: 100px;
    // margin-bottom: 50px;
    h3 {
        color: $third-color;
        // font-family: $main-font-bold;
        margin-bottom: 46px;
        text-transform: uppercase;
    }
    .react-multi-carousel-list {
        padding-bottom: 20px;
        .react-multiple-carousel__arrow--left {
            left: calc(50% - 55px) !important;
        }
        .react-multiple-carousel__arrow--right {
            right: calc(50% - 55px) !important;
        }
    }
}
.agent-of__item {
    cursor: pointer;

    overflow: hidden;
    padding: 10px;
    display: block;
    width: 95%;
    margin: auto;
    line-height: 1;

    img {
        width: 100%;
        object-fit: contain;
        height: 200px;
        transition: 0.3s;
        filter: grayscale(100%);
        &:hover {
            filter: grayscale(0%);
            transition: 0.3s;
        }
    }
}

@include respond(large-phone) {
    .agent-of__item {
        max-width: 300px;
        margin: auto;
        margin-bottom: 20px;
    }
}
