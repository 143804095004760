@import "../../abstract/mixins";

.text-input-block {
    .input-group {
        background-color: #f1f1f1 !important;
        border-radius: 10px;
        padding: 8px 15px;
        margin-bottom: 15px !important;
        img {
            width: 25px;
            object-fit: scale-down;
            height: 25px;
        }
        .form-control,
        textarea {
            height: auto;
            resize: none;
            padding: 0;
            border: 0;
            background-color: #f1f1f1 !important;
            color: $primary-color !important;
            font-weight: bold;
            font-size: 18px;
            &::placeholder {
                font-size: 18px;
                color: #bcbcbc;
            }
        }
        textarea {
            min-height: 100px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
    background-color: transparent !important;
    box-shadow: 0 0 0 5rem #f1f1f1 inset !important;
    -webkit-text-fill-color: $primary-color !important;
}

@include respond(laptop) {
    .text-input-block {
        .input-group {
            padding: 8px 15px;
            .form-control,
            textarea {
                font-size: 16px;
                &::placeholder {
                    font-size: 16px;
                }
            }
            textarea {
                min-height: 100px;
            }
        }
    }
}
