@import "../../abstract/mixins";
.header {
    padding-top: 0px;
    // position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    background-color: $header-color;
    .header-contents {
        padding:5px 0;
        .logo-image {
            width: 155px;
            height: 60px;
        }
        .header-list {
            column-gap: 25px;
            margin-left: 30px;
            margin-bottom: 0;
            margin: auto;
            font-size: 15px;
            //   .activeHeader {
            //     a {
            //       color: $primary-color;
            //     }
            //   }
            li {
                a {
                    color: $secondary-color;
                    // font-family: $main-font-bold;
                    transition: ease-in-out 0.3s;
                    text-transform: uppercase;
                    color: $header_text;
                    img {
                        width: 149px;
                        height: 83px;
                    }
                    &:hover {
                        color: $third-text-color;
                        transition: ease-in-out 0.3s;
                    }
                }
                .classesButton {
                    text-transform: uppercase;

                    background-color: transparent;
                    color: $secondary-color;
                    &:hover {
                        color: $third-text-color;
                        transition: ease-in-out 0.3s;
                    }
                }
            }
            .activeHeader {
                a,
                .classesButton {
                    color: $header_text;
                    text-decoration: underline;
                    font-weight: 900;
                }
            }
        }
        .cart-fav {
            margin: auto;
            column-gap: 20px;
            .single-cart {
                position: relative;
                img {
                    width: 25px;
                    object-fit: scale-down;
                }
                span {
                    position: absolute;
                    background-color: $third-text-color;
                    color: #fff;
                    font-size: 11px;
                    border-radius: 50%;
                    min-width: 22px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: -7px;
                    right: -10px;
                    border: 2px solid #fff;
                    font-weight: bold;
                }
            }
            .language-switch {
                color: $header_text;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .user-auth-Buttons {
            margin-left: auto;
            font-weight: bold;
            text-transform: capitalize;
            color: $secondary-color;
            font-size: 16px;


            a {
                color: $header_text;
                transition: ease-in-out 0.3s;

                &:hover {
                    color:  $third-text-color;
                    transition: ease-in-out 0.3s;
                }
            }
        }

        .user-dropdown {
            column-gap: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            .dropdown {
                button {
                    background-color: transparent;
                    border: 0;
                    color: $header_text;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    &::after {
                        margin-left: 16px;
                    }
                    div {
                        text-align: left;
                        span {
                            font-size: 11px;
                            margin-bottom: -3px;
                            display: block;
                        }
                        p {
                            margin: 0;
                            font-weight: bold;
                            color: $forth-text-color;
                        }
                    }
                }
            }

            .dropdown .dropdown-menu {
                // padding-right: 10px;
                // padding-left: 10px;
                background-color: $primary-color !important;
                border-radius: 10px;
                -webkit-transform: translate(-55px, 50px) !important;
                transform: translate(-55px, 50px) !important;
                &:after {
                    content: "";
                    position: absolute;
                    top: -14px;
                    left: calc(50% - 15px);
                    clear: both;
                    width: 0;
                    height: 0;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid $primary-color;
                    border-left: 15px solid transparent;
                }
                .dropdown-item {
                    color: #fff;
                    border-bottom: 1px solid rgba(51, 51, 51, 0.21176470588235294);
                    &:hover {
                        color: #fff;
                        background-color: $third-text-color !important;
                    }
                }
            }
        }
    }
}
.languageLoaderModal {
    z-index: 99999999999999991012 !important;
}
// push menu start
.mobile-push-menu {
    display: none;
    color: $primary-color;
    ul {
        padding: 0;
        li {
            div {
                padding-bottom: 10px;
            }
        }
    }
}

.bm-overlay {
    top: 0;
    left: 0;
    z-index: 9888888 !important;
}

.bm-burger-button {
    position: relative !important;
    margin-right: auto;
}

.bm-menu-wrap {
    top: 0;
    left: 0;
    z-index: 9999999999 !important;
    width: 40% !important;
    background-color: $primary-color;
}

.sideMenu_logo {
    text-align: center;
    background-color: #fff;

    img {
        margin: auto;
        padding: 8px 0;
    }

    &:focus {
        outline: none !important;
    }
}

.sideMenu_List {
    margin-top: 20px;
    padding: 0 10px;
    h3 {
        color: #fff;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
        margin-left: -20px;
        padding-left: 5px;
    }
    span,
    a,
    button {
        display: block;
        padding: 5px;
        // color: white !important;
        color: #ffffff !important;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            padding: 5px;
            border-radius: 50px;
            width: 35px;
            height: 35px;
            background-color: $primary-color;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 0;
            font-size: 13px;
            i {
                font-size: 10px;
                // margin-left: 20px;
                // margin-right: 3px;
            }
        }
    }

    span {
        font-weight: bold;
        letter-spacing: 1px;
    }

    li {
        text-transform: capitalize;
        border-bottom: 1px solid #ffffff6e;

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }

    button {
        width: 100%;
        text-align: left;
        background: transparent;
        column-gap: 0;
        width: 100%;
    }
    .sideMenu_List__img {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            width: 20%;
            border-radius: 50%;
        }
        span {
            width: 100%;
            display: block;
            text-align: start;
        }
    }
    // Accordion  Style(tablet and less) Start
    .accordion {
        border-bottom: 1px solid #ffffff6e;
        margin-bottom: 16px;
        .accordion-item {
            background-color: #50a433 !important;
        }
        .accordion-button:not(.collapsed) {
            background-color: #50a433 !important;
        }
        .accordion-button::after {
            filter: brightness(500) !important;
            margin: auto;
        }
        .accordion-button:not(.collapsed) {
            box-shadow: none;
        }
        .cart-and-favourite-mobile {
            p {
                margin: 0;
            }
            .cart-and-favourite-mobile__qty {
                border-radius: 20px;
                background-color: $third-text-color;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
            }
        }
    }
}

.sideMenu_loginButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    span {
        width: 50%;
        text-align: center;
        border: 0;

        &:first-child {
            padding-right: 5px;
            border-right: 2px solid white;
        }
    }
}
// push menu end
// Arabic Style Start

.header-arabic .header-contents .user-auth-Buttons {
    margin-left: 0;
}
.header .header-contents .header-list {
    padding: 0 20px 0 0;
}
.header-arabic .header-contents .user-dropdown .dropdown .dropdown-menu .dropdown-item {
    text-align: right;
}
.header-arabic .header-contents .user-dropdown .dropdown .dropdown-menu {
    background-color: #50a433 !important;
    border-radius: 10px;
    transform: translate(10px, 50px) !important;
}
.header-arabic .header-contents .user-dropdown .dropdown button div span {
    text-align: right;
}
.header-arabic .header-contents .user-dropdown .dropdown button::after {
    margin-left: auto;
    margin-right: 16px;
}

// Arabic Style End

@include respond(smallDesktop) {
    .header .header-contents .header-list li a {
        font-size: 13px;
    }
}
@include respond(laptop) {
    .header .header-contents .header-list li a {
        font-size: 11px;
    }
    .header .header-contents .header-list {
        grid-column-gap: 25px;
        column-gap: 16px;
        margin-left: 30px;
        margin-bottom: 0;
    }
    .header .header-contents .user-dropdown img {
        width: 45px;
        height: 45px;
    }
}
@include respond(small-laptop) {
    .header .header-contents .header-list {
        margin-left: 0;
        column-gap: 15px;
        a {
            font-size: 15px;
        }
    }
    .header .header-contents .user-auth-Buttons a {
        font-size: 15px;
    }

    .header a img {
        max-width: 130px;
    }
    header .header-contents .cart-fav {
        column-gap: 15px;
    }
    .header .header-contents .user-dropdown {
        column-gap: 5px;
    }
    .header .header-contents .user-dropdown img {
        width: 35px;
        height: 35px;
    }
    .header .header-contents .user-dropdown .dropdown button::after {
        margin-left: 10px;
    }
    .header .header-contents .user-dropdown .dropdown button div p {
        font-size: 15px;
    }

    .header .header-contents {
        justify-content: space-between;
    }
    .header .header-contents .header-list,
    .cart-fav,
    .user-auth-Buttons {
        display: none;
    }

    .header .mobile-push-menu {
        display: block;
    }

    .mobile-push-menu .bm-icon {
        font-size: 22px;
    }
}
@include respond(large-phone) {
    // push menu start
    .bm-menu-wrap {
        width: 60% !important;
    }

    .bm-menu-wrap {
        width: 60% !important;
    }

    .sideMenu_logo img {
        max-width: 150px;
    }

    .sideMenu_List a,
    .sideMenu_List button,
    .sideMenu_List span {
        font-size: 14px;
    }
    // push menu end
}
