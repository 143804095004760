.platform-block {
    border: 1px solid #e9e9e9;
    border-radius: 50px;
    margin-top: 10px;
    display: block;
    .platform-block__img__container {
        height: 100%;
        img {
            height: 100%;
            border-radius: 50px;
            width: 100%;
            object-fit: cover !important;
        }
    }
    .platform-block__info {
        padding: 20px;
        .platform-block__info__title {
            font-size: 25px;
            font-weight: 600;
            color: #0cb496;
            margin-bottom: 10px;
        }

        .platform-block__info__disc {
            color: #707070;
            font-size: 16px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
        }
    }
    transition: 0.3s;
    &:hover {
        box-shadow: 0 0 8px 1px #70707057;

        transition: 0.3s;
    }
    
}
.row .col-md-7{
        margin: 20px auto !important;
    }
@media (max-width: 767px) {
    .platform-block {
        .platform-block__info {
            padding: 20px !important;
        }
    }
}
