.contact-us {
    padding-top: 40px;
    overflow: hidden;
}
.page-label {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    //   border-bottom-left-radius: 50px;
    //   border-top-left-radius: 50px;

    height: 60px;
    width: 30%;
    position: absolute;
    background: $third-text-color;
    background: linear-gradient(270deg, #c32126, #c32126);

}
.page-title {
    position: relative;
    color: #fff;
    padding-top: 14px;
    // font-size: 46px;
    // font-family: $main-font-bold;
    text-transform: capitalize;
    padding-left: 0px;
}

.SearchTitle {
    font-size: 23px;
}

.form-box {
    position: relative;
    background-color: #fff;
    padding: 30px 50px;
    border-radius: 20px;
    box-shadow: 0 0 2px 0 silver;
    margin-top: 80px;
    .main-form-img {
        position: absolute;
        top: -100px;
        right: -50px;
        width: 540px;
        z-index: 99;
    }
}
.contact-details {
    padding: 10px;
    position: relative;
    border-radius: 20px;
    h4 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    div {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        ul {
            padding: 0;
            margin: 0;
        }
        .addresses-list {
            padding: 0;
            width: 100%;
            li {
                margin-bottom: 25px;
                &:last-child {
                    margin: 0;
                }
                p {
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
                span {
                    color: #707070;
                    font-size: 14px;
                    line-height: 1.2;
                    display: block;
                }
                a {
                    color: #707070;
                    font-size: 14px;
                    line-height: 1.2;
                    display: block;

                    &:hover {
                        color: $primary-color;
                        transition: 0.5s;
                    }
                }
            }
        }
        .social-media {
            position: relative;
            z-index: 999;
            a {
                display: block;
                margin-bottom: 8px;
                color: $primary-color;
                transition: ease-in-out 0.3s;

                &:hover {
                    color: $secondary-color;
                    transition: ease-in-out 0.3s;
                }
            }
        }
    }
}

.form-col {
    .text-input-block .input-group textarea {
        min-height: 202px;
    }
}
// for favorites page

.favorites-products {
    margin-bottom: 55px;
}
// for favorites page end
// Team page style Start
.team-products {
    .team-products__row {
        row-gap: 25px;
    }
    position: relative;
    .team-card {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 5px 0 silver;
        background-color: white;
        padding: 20px;
        padding-top: 40px;
        user-select: none;
        .team-card__img-container {
            position: relative;
            z-index: 996;
            overflow: hidden;
            display: flex;
            justify-content: center;
            .team-card__img {
                // width: 70%;
                width: 180px;
                height: 180px;
                // cursor: pointer;
                object-fit: scale-down;
                border-radius: 20px;
                margin-bottom: 25px;
            }
        }
        .team-card__name {
            position: relative;
            z-index: 997;
            color: $third-color;
            font-size: 20px;
            margin-bottom: 10px;
            // cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: bold;
            transition: ease-in-out 0.3s all;
        }
        .team-card__position {
            position: relative;
            z-index: 998;
            color: $third-color;
            font-size: 17px;
            margin-bottom: 10px;
            // cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            transition: ease-in-out 0.3s all;
        }
        .team-card__text {
            position: relative;
            z-index: 999;
            transition: ease-in-out 0.3s all;
            font-size: 15px;
        }
        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $primary-color;
            overflow: hidden;
            width: 100%;
            height: 100%;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            border-radius: 50px;
        }
        &:hover {
            .overlay {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }

            .team-card__name,
            .team-card__position,
            .team-card__text {
                color: white;
                transition: ease-in-out 0.3s all;
            }
        }
    }
}
// Team page style End

// Arabic Style Start
// .contact-us-arabic {
//     .form-box .main-form-img {
//         right: auto !important;
//         left: -50px !important;
//         transform: scaleX(-1) !important;
//     }
//     .page-label {
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//         border-bottom-left-radius: 50px;
//         border-top-left-radius: 50px;

//     }
//     .form-select {
//         background-position: left 0.75rem center;
//     }
// }
// Arabic Style End
@include respond(laptop) {
    .form-box .main-form-img {
        right: -15px;
        width: 480px;
    }
}
@include respond(small-laptop) {
    .auth .form-box .main-form-img,
    .form-box .main-form-img {
        top: -100px;
        right: -20px;
        width: 315px !important;
    }
    .contact-details {
        margin-top: 0px;
    }
    .contact-details div .addresses-list li span {
        font-size: 13px;
    }
    .page-title {
        font-size: 40px;
    }
}
@include respond(tablet) {
    .contact-details div .addresses-list li {
        margin-bottom: 15px;
    }
    .form-box .main-form-img {
        top: -60px;
        right: -9px;
        width: 250px !important;
    }
    .page-label {
        width: 56%;
    }
    .page-title {
        font-size: 30px;
        padding-top: 19px;
    }
    .form-box .main-form-img {
        display: none;
    }
}

@include respond(phone) {
    .form-box .main-form-img {
        top: -25px;
        right: -9px;
        width: 150px !important;
    }
    .page-title {
        font-size: 16px;
        padding-top: 28px !important;
    }
}
