.products-filter {
    .FilterTitle {
        display: flex;
        justify-content: space-between;
        line-height: 1.7;
        border-bottom: 2px solid #f0f0f0;
        padding-bottom: 9px;

        h1 {
            color: #515151;
            font-family: $primaryFontBoldt;
            font-size: 25px;
        }

        a {
            font-size: 14px;
            font-family: $primaryFontBoldt;
            color: $primary-color !important;
            margin-top: 5px;
            cursor: pointer;
        }
    }

    .AccordionFilters {
        margin: 10px 0;
    }

    .accordion-button:not(.collapsed) {
        color: #515151;
        background-color: transparent;
        font-family: $primaryFontBoldt;
        font-size: 17px;
    }

    .accordion-button {
        padding: 1rem 0;
    }

    .accordion-body {
        padding: 1rem 9px;

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 32px;
            margin-top: 13px;
        }
    }

    /* Customize the label (the container) */
    .CehckBoxContainer {
        display: block;
        position: relative;
        padding-left: 32px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        user-select: none;
        font-family: $primaryFontBoldt;
        color: #575756;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #dbdbdb;
            color: $primary-color;
        }

        /* On mouse-over, add a grey background color */
        .CehckBoxContainer:hover input ~ .checkmark {
            background-color: $primary-color;
        }

        /* On mouse-over, add a grey background color */
        .CehckBoxContainer:hover input ~ .checkmark {
            background-color: $primary-color;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
            background-color: $primary-color;
            border: 2px solid $primary-color;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
            display: block;
            color: $primary-color;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .CehckBoxContainer__upText {
            font-size: 14px;
        }
        .fa {
            color: $forth-text-color;
        }
        &.ActiveFa {
            .CehckBoxContainer__upText,
            .fa {
                color: $primary-color !important;
            }
        }
    }
    .CehckBoxContainerActive {
        color: $primary-color;
    }

    .btn-group {
        column-gap: 14px;
        input {
            padding: 7px 20px;
            background: none;
            border: 1px solid #f7f7f7;
            border-radius: 4px;
            color: #000000;
            text-align: center;
            &::placeholder {
                color: silver;
            }
        }
        button {
            padding: 7px 20px;
            background: none;
            border: 1px solid #fcfcfc;
            border-radius: 4px;
            color: #000000;
        }

        button:last-child {
            padding: 7px 20px;
            background: #fcfcfc;
            border: 1px solid #fcfcfc;
            border-radius: 4px;
            color: #000000;
        }

        button:last-child:hover {
            background: $primary-color;
            transition: 0.4s;
            color: #fff;
        }
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d3d2d2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 1px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .open_switch {
        background-color: $primary-color;
    }
    input:checked ~ .open_switch::before {
        background-color: #fff !important;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    input:focus + .slider {
        box-shadow: 0 0 1px $primary-color;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .SwitchButt {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;

        h3 {
            font-size: 16px;
            font-family: $primaryFontBoldt;
            color: #575756;
        }
    }

    &.products-filter-arabic {
        .FilterTitle {
            padding-left: 0;
        }
        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }

        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }
    }
}
.filter-icon {
    padding: 5px;
    border-radius: 5px;
    position: fixed;
    background-color: $primary-color;
    z-index: 999;
    margin-top: -40px;

    img {
        filter: brightness(500);
    }
    p {
        margin-bottom: 0;
        font-size: 12px;
        color: #fff;
    }
}

.modal-arabic {
    .accordion-button::after {
        margin-left: 0;
        margin-right: auto;
    }
}
@include respond(laptop) {
    .products-filter .CehckBoxContainer .checkmark {
        width: 20px;
        height: 20px;
    }

    .products-filter .CehckBoxContainer .checkmark:after {
        left: 5px;
        height: 13px;
        top: 0;
    }
    .products-filter-arabic {
        .FilterTitle a {
            font-size: 14px;
        }
    }
}

@include respond(large-phone) {
    .products-filter {
        width: 100%;
        left: 0;
        right: 0;
        z-index: 99999999999;
        background: white;
        top: 0;
        height: 100vh;
        padding: 16px;
    }
    .podMob {
        display: inline !important;
    }
    .products-filter-arabic {
        .FilterTitle {
            font-size: 10px;
        }
    }
}

@include respond(phone) {
    .products-filter {
        // position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 99999999999;
        background: white;
        top: 0;
        height: 100vh;
        padding: 16px;
    }

    .podMob {
        display: inline !important;
    }
}
