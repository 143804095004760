@import "../../../abstract/mixins";
.The-blog {
    .the-blog__background-image {
        position: absolute;
        z-index: -50;
        bottom: -606px;
        left: -663px;
    }
    .the-blog__section {
        display: flex;
        justify-content: center;
        background-color: #fff;
        // height: 470px;
        .the-blog__image-container {
            .the-blog__left-section__image {
                border-radius: 50%;
                // object-fit: contain;
                object-fit: fill;
                height: 266px;
                width: 266px;
            }
        }
        .The-blog__section__title {
            text-align: center;
            color: #575757;
            font-size: 20px;
            font-family: $main-font-bold;
        }
        .The-blog__section__title-big-one {
            margin: 0;
        }
        .the-blog__section__date {
            color: $forth-text-color;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
            height: 20px;
        }
        .The-blog__section__description {
            font-size: 14px;
            color: #606060;
        }
    }
}
@include respond(smallDesktop) {
    .The-blog {
        .the-blog__background-image {
            // bottom: -183px;
            // left: -424px;
            // width: 648px;
        }
        .the-blog__section {
            .the-blog__image-container {
                text-align: center;
                margin-top: 10px;
                .the-blog__left-section__image {
                    height: 200px;
                    width: 200px;
                }
            }
        }
    }
}
@include respond(laptop) {
    .The-blog {
        .the-blog__section {
            // height: 400px;
        }
        .the-blog__background-image {
            // bottom: -313px;
            // left: -519px;
            // width: 722px;
        }
    }
}
@include respond(small-laptop) {
    .The-blog {
        padding: 60px 0px;
        .the-blog__section {
            // height: 360px;
        }
        .the-blog__background-image {
            // bottom: -316px;
            // left: -481px;
            // width: 687px;
        }
    }
}
@include respond(tablet) {
    .The-blog {
        padding: 60px 0px;
        .the-blog__section {
            // height: 460px;
        }
        .the-blog__background-image {
            // left: -481px;
            // width: 687px;
        }
    }
}
@include respond(large-phone) {
    .The-blog {
        .the-blog__background-image {
            display: none;
        }
    }
}
