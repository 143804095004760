@import "./variable";

@mixin respond($breakpoint, $min: 0, $max: 100vw) {
  @if $breakpoint==custom-media {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @if $breakpoint==phone {
    @media (max-width: 425px) {
      @content;
    }
  }

  @if $breakpoint==large-phone {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==small-laptop {
    @media (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==laptop {
    @media (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==smallDesktop {
    @media (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (min-width: 1400px) {
      @content;
    }
  }
}

// @mixin tabs {
//     box-shadow: 0 7px 7px gainsboro;

//     .wrapper {
//         .tab {
//             column-gap: 16px;
//             cursor: pointer;
//             padding: 0.5rem;
//             border-bottom: 3px solid transparent;
//             position: relative;

//             &:not(:last-of-type)::after {
//                 content: "";
//                 position: absolute;
//                 top: 0;
//                 right: -8px;
//                 width: 1px;
//                 height: 80%;
//                 background-color: gainsboro;
//             }

//             img {
//                 width: 32px;
//             }

//             .tab__text {
//                 font-weight: 500;
//             }

//             &.--selected-tab {
//                 border-bottom-color: $primary-color;
//             }
//         }
//     }
// }
