@import "../../abstract/mixins";

.carousel {
    .carousel__mainImg {
        width: 100%;
        height: 620px;
    }
    .carousel__item__img {
        height: 512px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .container {
        position: relative;
    }
    .carousel-caption {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 50px;
    }
    .carousel-indicators {
        // bottom: 30px !important;
    }
    .carousel__item__info__link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 212.22px;
        height: 46.71px;
        background-color: $Slider_Button;
        border-radius: 50px;
        color: #fff;
        font-size: 18px;
        border: 1px solid $Slider_Button;
        transition: 0.3s;
        margin-top: 100px;

        &:hover {
            background-color: transparent;
            transition: 0.3s;
        }
    }
    .carousel__item__info {
        padding: 0 30px;
        margin-top: 120px;

        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        // align-items: space-around;
        text-align: left;
        .carousel__item__info__title {
            color: #fff;
            font-size: 27px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 32px;
            .small-sep {
                display: inline-block;
                width: 51px;
                height: 2px;
                background-color: #fff;
                margin-top: 30px;
            }
        }
        .carousel__item__info__desc {
            color: #fff;
            font-size: 19px;
            max-width: 90%;
        }
    }
    &.carousel-arabic {
        .carousel__item__info {
            text-align: right;
        }
    }
}

@include respond(smallDesktop) {
    .carousel {
        .carousel__mainImg {
            height: 520px;
        }
        .carousel-caption {
            bottom: 25px;
        }
        .carousel__item__info {
            margin-top: 60px;
            .carousel__item__info__title {
                font-size: 24px;
            }
            .carousel__item__info__desc {
                font-size: 17px;
            }
        }
        .carousel__item__info__link {
            margin-top: 80px;
        }
        .carousel__item__img {
            height: 440px;
        }
    }
}

@include respond(laptop) {
    .carousel {
        .carousel__mainImg {
            height: 460px;
        }
        .carousel-caption {
            bottom: 20px;
        }
        .carousel__item__info {
            margin-top: 40px;
            .carousel__item__info__title {
                font-size: 22px;
            }
            .carousel__item__info__desc {
                font-size: 15px;
            }
        }
        .carousel__item__info__link {
            width: 180px;
            height: 40px;
            font-size: 15px;
            margin-top: 50px;
        }
        .carousel__item__img {
            height: 367px;
        }
    }
}
@include respond(small-laptop) {
    .carousel {
        .carousel__mainImg {
            height: 420px;
        }
        .carousel__item__info {
            padding: 0 10px;
            margin-top: 40px;
            .carousel__item__info__title {
                font-size: 20px;
            }
            .carousel__item__info__desc {
                font-size: 14px;
            }
        }
        .carousel__item__info__link {
            width: 160px;
            height: 35px;
            font-size: 15px;
            margin-top: 40px;
        }
        .carousel__item__img {
            height: 287px;
        }
    }
}

@include respond(tablet) {
    .carousel {
        .carousel__mainImg {
            height: 610px;
            object-fit: cover;
        }
        .carousel__item__info {
            padding: 0 30px;
            margin-top: 40px;
            .carousel__item__info__title {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .carousel__item__info__desc {
                font-size: 14px;
            }
        }
        .carousel__item__info__link {
            width: 160px;
            height: 35px;
            font-size: 15px;
            margin-top: 40px;
        }
        .carousel__item__img {
            height: auto;
            img {
                width: 230px;
                height: 280px;
            }
        }
    }
}

@include respond(phone) {
    .carousel {
        .carousel__mainImg {
            height: 600px;
        }
        .carousel__item__info {
            padding: 0 20px;
            margin-top: 30px;
            .carousel__item__info__title {
                font-size: 17px;
                margin-bottom: 15px;
            }
            .carousel__item__info__desc {
                font-size: 13px;
            }
        }
        .carousel__item__info__link {
            width: 130px;
            height: 30px;
            font-size: 13px;
            margin-top: 20px;
        }
        .carousel__item__img img {
            width: 230px;
            height: 280px;
        }
    }
}

// .carousel-indicators {
//     bottom: 30px !important;
//     right: calc(50% - 50px);
//     width: 100px;
//     left: auto;
//     bottom: 31px;
//     margin: 0;
//     // margin: 50%;
//     margin-bottom: 20px;
//     overflow: hidden;
//     border-radius: 50%;
//     overflow: hidden;
// }
// .carousel-control-prev {
//     width: 50%;
//     justify-content: flex-start;
//     .carousel-control-prev-icon {
//         bottom: 46px;
//         position: absolute;
//         left: calc(93% - 5px);
//         width: 1rem;
//     }
// }
// .carousel-control-next {
//     width: 50%;
//     .carousel-control-next-icon {
//         bottom: 46px;
//         position: absolute;
//         right: calc(93% - 5px);
//         width: 1rem;
//     }
// }
