* {
    &::-webkit-scrollbar {
        width: 0rem;
        height: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: darken(#cdcecf, 1%);
        border-radius: 5px;
    }
}
.dd {
    height: 54px;
    display: flex;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 0rem;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
    }
}

.title-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;
    .title-menu-container {
        margin-top: -2px;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: darken($primary-color, 10%);
            border-radius: 20px;
        }
    }
    .title-menu-container div{
        margin-left: 30px;
        margin-bottom: 20px;
        padding: 0;
    }
    .menu-node {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        padding: 0;
        .menu-node__header {
            border-radius: 50%;
            transition: 0.3s;
            img {
                max-height: 40px;
                margin: auto;
            }
        }
        .menu-node__body {
            // margin-top: 20px;
            p {
                margin: 10px 0;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 600;
            }
        }

        &:hover {
            .menu-node__header {
                box-shadow: 0 0 55px 3px;
                transition: 0.4s;
            }
        }
    }
}

#triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #000;
    margin-top: 10px;
}
.titles-sub {
    position: static !important;
    width: 100%;
    // overflow-x: overlay;
    display: flex !important;
    align-items: center !important;
    border: none !important;
    // background-color: red !important;
    border-radius: 0 !important;
    padding: 0 !important;
    // margin-top: -6px !important;
    min-width: auto !important;

    &.dropdown-menu {
        background: #c42228 !important;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: darken(#c42228, 10%) !important;
        }
    }

    .dropdown-item {
        color: #fff;
        // width: auto !important;
        width: 100% !important;
        padding: 15px 0 !important;
        text-align: center;
        &:hover {
            background-color: transparent !important;
            opacity: 0.9;
            transition: 0.3s;
            color: #fff;
        }
        &:focus {
            background-color: transparent !important;
            border-bottom: 3px solid #cdcecf;
        }
        &:active {
            border-bottom: 3px solid #cdcecf;
            background-color: transparent !important;
        }
    }
    .active {
        border-bottom: 3px solid #cdcecf;
        background-color: transparent !important;
    }
}
.title-menu__sub {
    display: flex;
    justify-content: start;
    // margin-top: 17px;
    height: auto;
    margin-top: -2px;
    .sub-menu {
        width: 100%;
        // background-color: #ee861d;
        // box-shadow: 0 0 0 3px #ee861d;
        // border-radius: 40px;
        overflow-y: hidden;

        height: 51px;
        .sub-menu__list {
            display: flex;
            align-items: center;
            margin: 0;
            height: 51px;
            .sub-menu__list__item {
                padding: 0 20px !important;
                cursor: pointer;
                color: #fff !important;
                font-size: 18px;
                margin: 0 12px;
                white-space: pre;
            }
        }
    }
}

@include respond(large-phone) {
    #triangle-up {
        margin-top: 20px;
    }
    .title-menu-container .text-center {
        padding: 5px 5px 0 5px !important;
    }
    .title-menu .menu-node .menu-node__body p {
        font-size: 14px;
        white-space: nowrap;
    }
    .title-menu-container {
        div {
            // padding: 0 10px !important;
            padding-bottom: 0 !important;
            // padding-left: 5px !important;
        }
    }
    .menu-node {
        padding: 0 15px !important;
        margin-bottom: -1px;
    }
    .title-menu .menu-node:hover .menu-node__header {
        width: 86px !important;
        padding: 14px 10px !important;
    }
    .title-menu__sub {
        .sub-menu {
            height: 34px;

            .sub-menu__list {
                height: 34px;
                .sub-menu__list__item {
                    padding: 0 !important;
                }
            }
        }
    }
    .menu-node {
        img {
            width: 50px;
            height: 50px;
        }
    }
}
