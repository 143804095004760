.about-block {
    margin-top: 150px;
    .about-block__contents {
        padding: 0 40px;
        position: relative;
        border-left: 9px solid $primary-color;
        img {
            // position: absolute;
            // right: -40px;
            // width: 356px; 
            bottom: calc(50% - 190px);
            object-fit: contain;
            width: 100%;
        }

        color: #000;
        h3 {
            text-transform: capitalize;
            color: $primary-color;
        }
        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}
// Arabic Style Start
.about-block-arabic {
    .row {
        flex-direction: row-reverse;
    }
}
// Arabic Style End
@include respond(laptop) {
    .about-block .about-block__contents img {
        // right: -28px;
        // bottom: -20px;
        width: 380px;
    }
    .about-block .about-block__contents {
        padding: 30px;
    }
}
@include respond(small-laptop) {
    .about-block .about-block__contents img {
        display: none;
    }
    .about-block {
        margin-top: 100px;
        border-radius: 30px;
    }
}
